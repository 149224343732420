import Heading from "../../layout/Heading";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL, POSTS_URL, NYHET_URL_KORT } from "../../../constants/api";
import TextLoader from "../loader/TextLoader";
import Alert from "react-bootstrap/Alert";
import { format } from "date-fns";

function NewsCard({ sectionClass, addClass, addClassContainer, per_page, linkClass }) {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = BASE_URL + POSTS_URL + NYHET_URL_KORT + per_page;

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            setNews(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <TextLoader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }

  if (news.length === 0) {
    return <h2 className="empty-array">Ingen nyheter enda...</h2>;
  }

  return (
    <>
      {news.map(function (item) {
        if (item._embedded["wp:featuredmedia"]) {
          return (
            <div data-aos="fade-up" data-aos-offset="70" key={item.id} className={sectionClass}>
              <Link to={`/news-specific/${item.id}`} className={linkClass}>
                <div className={addClass}>
                  <div>
                    <img src={item._embedded["wp:featuredmedia"]["0"].source_url} alt={item._embedded["wp:featuredmedia"]["0"].alt_text} />
                  </div>
                  <div className={addClassContainer}>
                    <div className="latest-news-container-creator">
                      <p className="latest-news-container-creator-date">{format(new Date(item.date), "dd-MMMM-yyyy")}</p>
                      <p className="latest-news-container-creator-author">
                        Av <span>{item._embedded["author"][0].name}</span>
                      </p>
                    </div>
                    <Heading size="3" content={item.title.rendered} />
                    <p className="news-content-container" dangerouslySetInnerHTML={{ __html: item.content.rendered }}></p>
                    <div className="news-content-container-read-more">Les mer</div>
                  </div>
                </div>
              </Link>
            </div>
          );
        }
        return null;
      })}
    </>
  );
}

export default NewsCard;
