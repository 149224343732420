import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import CoopLogo from "../../images/coop-logo-2-removebg-preview.png";
import { useState } from "react";

export default function NAV() {
  // Sets the animation for the hamburger icon on click | Line 25
  const [hamburger, setHamburger] = useState(false);

  const toggleHamburger = () => {
    setHamburger(!hamburger);
  };

  return (
    <header id="header">
      <NavLink to="/" className="brand-logo-mobile">
        <img src={CoopLogo} alt="Coop logo" className="brand-logo-image" />
      </NavLink>
      <Navbar expand="lg">
        <NavLink to="/" className="brand-logo-desktop">
          <img src={CoopLogo} alt="Coop logo" className="brand-logo-image" />
        </NavLink>
        <Container className="nav-container">
          <NavLink to="/" className="nav-link" id="avoid-active">
            <Navbar.Brand>Personalforeningen</Navbar.Brand>
          </NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <div className={"hamburger hamburger--spring" + (hamburger ? "hamburger hamburger--spring is-active" : "")} onClick={toggleHamburger} type="button">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <hr />
            <Nav>
              <NavLink to="/news" className="nav-link">
                Nyheter
                <i className="fa-solid fa-newspaper"></i>
              </NavLink>
              <NavLink to="/about" className="nav-link mobile">
                Om oss
                <i className="fa-solid fa-circle-info"></i>
              </NavLink>
              <NavLink to="/grant" className="nav-link mobile">
                Tilskudd
                <i className="fa-solid fa-gift"></i>
              </NavLink>
              <NavDropdown title="Info" id="collasible-nav-dropdown" className="nav-item dropdown desktop">
                <Dropdown.Item>
                  <NavLink to="/about" className="nav-link">
                    Om oss
                    <i className="fa-solid fa-circle-info"></i>
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink to="/grant" className="nav-link">
                    Tilskudd
                    <i className="fa-solid fa-gift"></i>
                  </NavLink>
                </Dropdown.Item>
              </NavDropdown>
              <NavLink to="/rental" className="nav-link">
                Utleie
                <i className="fa-solid fa-person-hiking"></i>
              </NavLink>
              <NavLink to="/contact" className="nav-link">
                Kontakt oss
                <i className="fa-solid fa-envelope"></i>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
