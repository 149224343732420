function ToTop() {
  const scrollToTop = (props) => {
    window.scrollTo(0, 0);

    return <>{props.children}</>;
  };
  return (
    <button onClick={scrollToTop} className="cta-alt toTop">
      <i className="fa-solid fa-arrow-up"></i>
      Tilbake til toppen
    </button>
  );
}

export default ToTop;
