import "./sass/style.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./components/layout/Nav";
import ScrollToTop from "./components/common/loader/ScrollToTop";
import { useEffect } from "react";

// Pages
import Home from "./components/pages/home/Home";
import About from "./components/pages/about/About";
import Rental from "./components/pages/rental/Rental";
import Contact from "./components/pages/contact/Contact";
import Grant from "./components/pages/grant/Grant";
import News from "./components/pages/news/News";
import NewsSpecific from "./components/pages/news/NewsSpecific";

// Footer
import Footer from "./components/layout/Footer";

// Animation styles
// Animation imports
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      // delay: 800,
      easing: "ease-in-out",
      duration: 650,
      offset: 250,
      once: true,
    });
  }, []);

  return (
    <Router>
      <Nav />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/rental" element={<Rental />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/grant" element={<Grant />} />
          <Route path="/news" element={<News />} />
          <Route path="/news-specific/:id" element={<NewsSpecific />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </Router>
  );
}

export default App;
