import { Container } from "react-bootstrap";
import Heading from "../../layout/Heading";
import NewsCard from "../cards/NewsCard";

function HomeNews() {
  return (
    <>
      <Container>
        <Heading size="3" content="Siste nytt" addClass="heading-center" />
        <section className="news-page-card">
          <NewsCard per_page="&per_page=3" sectionClass="latest-news-section" addClass="latest-news home-news" addClassContainer="news-container" />
        </section>
      </Container>
    </>
  );
}

export default HomeNews;
