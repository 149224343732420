import Heading from "../../layout/Heading";
import { useState, useEffect } from "react";
import { BASE_URL, TILSKUDD_URL_INFO } from "../../../constants/api";
import TextLoader from "../loader/TextLoader";
import Alert from "react-bootstrap/Alert";
import { Container } from "react-bootstrap";

function GrantInfo() {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = BASE_URL + TILSKUDD_URL_INFO;

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            console.log(json);
            setInfo(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <TextLoader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }

  return (
    <>
      <Container className="about-info">
        <div className="about-info-container" key={info.id}>
          <Heading size="2" content={info.title.rendered} />
          <div className="about-info-container-text">
            <p className="inline-wp-styles" dangerouslySetInnerHTML={{ __html: info.content.rendered }}></p>
          </div>
        </div>
      </Container>
    </>
  );
}

export default GrantInfo;
