import Heading from "../../layout/Heading";
import { useState, useEffect } from "react";
import TextLoader from "../loader/TextLoader";
import Alert from "react-bootstrap/Alert";
import { BASE_URL, UTLEIE_URL_BOOKING } from "../../../constants/api";

function RentalInfoOne() {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = BASE_URL + UTLEIE_URL_BOOKING;

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            console.log(json);
            setInfo(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <TextLoader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }
  return (
    <>
      <section>
        <Heading size="2" content={info.title.rendered} />
        <p className="inline-wp-styles" dangerouslySetInnerHTML={{ __html: info.content.rendered }}></p>
        <a href="https://coop.bookhus.no/account/login?ReturnUrl=%2f" className="cta-standard">
          <i className="fa-solid fa-house-chimney"></i>
          Lei hytte/leilighet nå
        </a>
      </section>
    </>
  );
}

export default RentalInfoOne;
