import Accordion from "react-bootstrap/Accordion";
import Banner from "../../common/banners/Banner";
import FaqAccordion from "../../common/contactComponents/FaqAccordion";
import ContactForm from "../../common/contactComponents/ContactForm";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { BASE_URL, KONTAKT_URL_BILDE } from "../../../constants/api";
import Alert from "react-bootstrap/Alert";
import Loader from "../../common/loader/Loader";
import Heading from "../../layout/Heading";
import ToTop from "../../common/toTop/ToTop";

function Contact() {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = BASE_URL + KONTAKT_URL_BILDE;

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            console.log(json);
            setInfo(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <Loader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }

  if (!info._embedded["wp:featuredmedia"]) {
    return (
      <main id="main">
        <Banner bannerImage="https://images.unsplash.com/photo-1630414178593-26f610c7ad2e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80" bannerClasses="banner-image" topContent="Kontakt" bottomContent="Oss her" />
        <Container>
          <div className="contact">
            <section id="contact-form">
              <ContactForm />
            </section>
            <div className="vertical-line"></div>
            <section id="FaQ">
              <Heading size="2" content="Ofte stilte spørsmål" addClass="heading-center" />
              <Accordion>
                <FaqAccordion />
              </Accordion>
            </section>
          </div>
        </Container>
        <ToTop />
      </main>
    );
  } else {
    return (
      <main id="main">
        <Banner bannerImage={info._embedded["wp:featuredmedia"]["0"].source_url} bannerClasses="banner-image" topContent="Kontakt" bottomContent="Oss her" />
        <Container>
          <div className="contact">
            <section id="contact-form">
              <ContactForm />
            </section>
            <div className="vertical-line"></div>
            <section id="FaQ">
              <Heading size="2" content="Ofte stilte spørsmål" addClass="heading-center" />
              <Accordion>
                <FaqAccordion />
              </Accordion>
            </section>
          </div>
        </Container>
        <ToTop />
      </main>
    );
  }
}

export default Contact;
