import Breadcrumb from "react-bootstrap/Breadcrumb";

function Breadcrumbs({ linkOne, linkTwo, linkThree }) {
  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">{linkOne}</Breadcrumb.Item>
      <Breadcrumb.Item href="/news">{linkTwo}</Breadcrumb.Item>
      <Breadcrumb.Item active>{linkThree}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default Breadcrumbs;
