import { Link } from "react-router-dom";
import Heading from "../../layout/Heading";
import { Container } from "react-bootstrap";
import { useEffect } from "react";

function HomeBanner() {
  // Finds the month on the users computer, and sets different seasonal images depending on the month. 0 is january, 1 february, up to 11 which is december
  useEffect(() => {
    const time = new Date().getMonth();
    console.log(time);

    // spring time | march april may
    if (time === 2 || time === 3 || time === 4) {
      document.querySelector(".home-image").classList.add("spring-image");
    } else {
      document.querySelector(".home-image").classList.remove("spring-image");
    }

    // summer time | juni juli august
    if (time === 5 || time === 6 || time === 7) {
      document.querySelector(".home-image").classList.add("summer-image");
    } else {
      document.querySelector(".home-image").classList.remove("summer-image");
    }

    // autumn time | september october november
    if (time === 8 || time === 9 || time === 10) {
      document.querySelector(".home-image").classList.add("autumn-image");
    } else {
      document.querySelector(".home-image").classList.remove("autumn-image");
    }

    // winter time | december january february
    if (time === 11 || time === 0 || time === 1) {
      document.querySelector(".home-image").classList.add("winter-image");
    } else {
      document.querySelector(".home-image").classList.remove("winter-image");
    }
  });

  return (
    <div className="home-banner">
      <div className="home-image">
        <div className="home-image-overlay-box"></div>
      </div>
      <div className="home-image-overlay">
        <Container>
          <Heading size="1" content="Ønsker du å leie på Hafjell eller Beitostølen?" />
          <hr />
          <Heading addClass="heading-regular" size="1" content="Eller er du kanskje nysgjerrig på hva personalforening er?" />
          <div className="home-image-overlay--ctas">
            <Link to="/rental" className="cta-standard">
              <i className="fa-solid fa-house-chimney"></i>
              Les om utleie
            </Link>
            <Link to="/about" className="cta-alt cta-alt--width">
              <i className="fa-solid fa-circle-info"></i>
              Om oss
            </Link>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default HomeBanner;
