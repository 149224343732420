import Heading from "../../layout/Heading";
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Toast from "react-bootstrap/Toast";
import { Container } from "react-bootstrap";

function GrantSubmission() {
  // Form states
  const [toast, setToast] = useState("");
  const [verified, setVerified] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // Reset states
  const [resetName, setResetName] = useState();
  const [resetNumber, setResetNumber] = useState();
  const [resetQuantity, setResetQuantity] = useState();
  const [resetMail, setResetMail] = useState();
  const [resetMessage, setResetMessage] = useState();

  const resetForm = () => {
    setResetName("");
    setResetNumber("");
    setResetQuantity("");
    setResetMail("");
    setResetMessage("");
  };

  // Makes sure the captcha is checked
  function onChange() {
    setVerified(!verified);
  }

  // When button is clicked, display different text, and remove after set time
  const submit = () => {
    setSubmitting(true);
    setTimeout(() => setSubmitting(false), 2000);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_4vmg9uk", "Søknad om tilskudd", form.current, "h39HGOr5RscnMAc21").then(
      (result) => {
        console.log(result.text);
        // Fills the div further down with this content based on success or error
        setToast(
          <Container>
            <Toast data-aos="fade-right" data-aos-offset="0" className="toast toast-success">
              <Toast.Header closeButton={false} className="toast-success-header">
                <strong className="me-auto">Melding sendt!</strong>
                <strong>Status 200</strong>
              </Toast.Header>
              <Toast.Body className="toast-body">
                <i class="fa-solid fa-circle-check"></i>Din melding er sendt! Du vil bli kontaktet fortløpende.
              </Toast.Body>
            </Toast>
          </Container>
        );
        // Remove this content after a set time
        setTimeout(() => setToast(false), 10000);
        resetForm();
        window.grecaptcha.reset();
        setVerified(true);
      },
      (error) => {
        console.log(error.text);
        setToast(
          <Container>
            <Toast data-aos="fade-right" data-aos-offset="0" className="toast toast-error">
              <Toast.Header closeButton={false} className="toast-error-header">
                <strong className="me-auto">Det skjedde en feil...</strong>
                <strong>Status 400</strong>
              </Toast.Header>
              <Toast.Body className="toast-body">
                <i className="fa-solid fa-circle-exclamation"></i>Beklager, meldingen ble ikke sendt. Prøv igjen. Hvis feilen vedvarer, prøv igjen senere.
              </Toast.Body>
            </Toast>
          </Container>
        );
        setTimeout(() => setToast(false), 10000);
      }
    );
  };

  return (
    <>
      <div className="grant-submission">
        <Heading size="3" addClass="heading-center" content="Send søknad om tilskudd" />
        <div className="grant-submission-container grant-submission-form">
          <form id="grant-form-mail-input" ref={form} onSubmit={sendEmail} name="Søknad om tilskudd" method="post">
            <div>
              <input type="text" name="emne" value="Søknad om tilskudd" id="hidden-form" />
            </div>

            <div>
              <label htmlFor="Navn">
                Fullt navn <span>*</span>
              </label>
              <input value={resetName} onChange={(e) => setResetName(e.target.value)} required="required" name="avsender" type="text" placeholder="Fullt navn" />
            </div>

            <div>
              <label htmlFor="Email">
                Din mail <span>*</span>
              </label>
              <input value={resetMail} onChange={(e) => setResetMail(e.target.value)} required="required" name="bruker_mail" type="email" placeholder="Epost" />
            </div>

            <div className="grant-form-flex">
              <div>
                <label htmlFor="Ansattnr">
                  Ditt ansattnr <span>*</span>
                </label>
                <input value={resetNumber} onChange={(e) => setResetNumber(e.target.value)} required="required" name="ansattnr" type="number" placeholder="Ansatt Nr" />
              </div>

              <div>
                <label htmlFor="Antall">
                  Hvor mange er dere? <span>*</span>
                </label>
                <input value={resetQuantity} onChange={(e) => setResetQuantity(e.target.value)} required="required" name="antall" type="number" placeholder="Hvor mange er dere?" />
              </div>
            </div>

            <div>
              <label htmlFor="Melding">
                Skriv en melding <span>*</span>
              </label>
              <textarea value={resetMessage} onChange={(e) => setResetMessage(e.target.value)} required="required" rows="7" name="melding" type="textarea" placeholder="Din melding" />
            </div>

            <div>
              <div className="capt-center">
                <ReCAPTCHA sitekey="6LdULRgjAAAAABu2KgUcyi9dXGohx4dD2KvXpWrS" onChange={onChange} />
              </div>
              <button onClick={submit} disabled={verified} type="submit" className="cta-standard cta-form cta-disabled">
                {submitting ? "Sender..." : "Send inn"}
              </button>
            </div>
            <div>{toast}</div>
          </form>
        </div>
      </div>
    </>
  );
}

export default GrantSubmission;
