import { Container } from "react-bootstrap";
import Banner from "../../common/banners/Banner";
import Heading from "../../layout/Heading";
import NewsCard from "../../common/cards/NewsCard";
import { useState, useEffect } from "react";
import { BASE_URL, NYHET_URL_SIDE } from "../../../constants/api";
import Alert from "react-bootstrap/Alert";
import Loader from "../../common/loader/Loader";
import ToTop from "../../common/toTop/ToTop";

function News() {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = BASE_URL + NYHET_URL_SIDE;

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            console.log(json);
            setInfo(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <Loader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }

  if (!info._embedded["wp:featuredmedia"]) {
    return (
      <>
        <main id="main">
          <Banner bannerImage="https://images.unsplash.com/photo-1630414178593-26f610c7ad2e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80" bannerClasses="banner-image" topContent="Våre" bottomContent="Nyheter" />
          <Container className="container-width-update">
            <div data-aos-offset="10" className="news-page-intro news-h2">
              <Heading size="2" content="Nyheter" />
            </div>
            <NewsCard linkClass="news-link" per_page="&per_page=100" sectionClass="news-page-section" addClass="news" addClassContainer="news-container ml-1" />
          </Container>
        </main>
      </>
    );
  } else {
    return (
      <>
        <main id="main">
          <Banner bannerImage={info._embedded["wp:featuredmedia"]["0"].source_url} bannerClasses="banner-image" topContent="Våre" bottomContent="Nyheter" />
          <Container className="container-width-update">
            <div data-aos-offset="10" className="news-page-intro news-h2">
              <Heading size="2" content="Nyheter" />
            </div>
            <NewsCard linkClass="news-link" per_page="&per_page=100" sectionClass="news-page-section" addClass="news" addClassContainer="news-container ml-1" />
          </Container>
          <ToTop />
        </main>
      </>
    );
  }
}

export default News;
