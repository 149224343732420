import React from "react";
import { Container } from "react-bootstrap";
import AboutLeaders from "../../common/aboutComponents/aboutRepresentatives/AboutLeaders";
import Heading from "../../layout/Heading";
import AboutMembers from "./aboutRepresentatives/AboutMembers";
import AboutPrefix from "./aboutRepresentatives/AboutPrefix";

function AboutRepresentatives() {
  return (
    <>
      <Container className="about-representatives">
        <Heading size="3" content="Personalforeningen:" />
        <AboutLeaders />
        <hr className="about-representatives-hr" />
        <AboutMembers />
        <hr className="about-representatives-hr" />
        <AboutPrefix />
      </Container>
    </>
  );
}

export default AboutRepresentatives;
