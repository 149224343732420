import { useEffect } from "react";

function Footer() {
  // Call this whenever a component renders
  // When window has reached bottom, change these styles, and wait 2.5s
  useEffect(() => {
    const sun = document.querySelector(".sun");
    const bg = document.querySelector("#mountains");

    window.onscroll = function () {
      setTimeout(function () {
        if (window.innerHeight + Math.ceil(window.pageYOffset) >= document.body.offsetHeight) {
          sun.style.bottom = "250px";
          sun.style.transition = "2s";

          bg.style.backgroundColor = "lightblue";
          bg.style.transition = "2s";
        } else {
          bg.style.backgroundColor = "transparent";
          bg.style.transition = "2s";

          sun.style.transition = "2s";
          sun.style.bottom = "-160px";
        }
      }, 1000);
    };
  }, []);

  return (
    <>
      <footer id="mountains">
        <div className="bg">
          <div className="mountain">
            <div className="mountain-top">
              <div className="mountain-cap-1"></div>
              <div className="mountain-cap-2"></div>
              <div className="mountain-cap-3"></div>
            </div>
          </div>
          <div className="mountain-two">
            <div className="mountain-top">
              <div className="mountain-cap-1"></div>
              <div className="mountain-cap-2"></div>
              <div className="mountain-cap-3"></div>
            </div>
          </div>
          <div className="mountain-three">
            <div className="mountain-top">
              <div className="mountain-cap-1"></div>
              <div className="mountain-cap-2"></div>
              <div className="mountain-cap-3"></div>
            </div>
          </div>
          <div className="sun"></div>
          <i className="fa-solid fa-person-hiking fa-person-hiking-flipped"></i>
        </div>
      </footer>
      <div className="mountain-floor"></div>
      <div className="footer">© 2022 Personalforeningen i Coop Øst</div>
    </>
  );
}

export default Footer;
