import Heading from "../../layout/Heading";
import Banner from "../../common/banners/Banner";
import Breadcrumbs from "../../layout/Breadcrumbs";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../common/loader/Loader";
import Alert from "react-bootstrap/Alert";
import { BASE_URL, POSTS_URL, NYHET_URL_KORT } from "../../../constants/api";
import { format } from "date-fns";
import ToTop from "../../common/toTop/ToTop";

function NewsSpecific() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  // Retrieve the id from url
  const { id } = useParams();

  if (!id) {
    navigate("/");
  }

  const url = BASE_URL + POSTS_URL + id + NYHET_URL_KORT;

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            console.log(json);
            setNews(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <Loader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }

  return (
    <main key={news.id} id="main news-specific-banner">
      <Banner bannerImage={news._embedded["wp:featuredmedia"]["0"].source_url} bannerClasses="banner-image" topContent={news.title.rendered} bottomContent={`Av ${news._embedded["author"][0].name}`} addId="banner-position" />
      <Container className="container-width-update news-specific">
        <Breadcrumbs linkOne="Hjem" linkTwo="Nyheter" linkThree={news.title.rendered} />
        <Heading size="1" content={news.title.rendered} />
        <section className="news-specific-details">
          <div>
            <p>{format(new Date(news.date), "dd-MMMM-yyyy")}</p>
            <p>{`Av ${news._embedded["author"][0].name}`}</p>
          </div>
          <p className="inline-wp-styles" dangerouslySetInnerHTML={{ __html: news.content.rendered }}></p>
        </section>
      </Container>
      <ToTop />
    </main>
  );
}

export default NewsSpecific;
