// Base url
export const BASE_URL = "https://personalforeningen.com/wp/wp-json/";

// Hva tilbyr vi?
export const FORSIDE_DEL_1 = "wp/v2/pages/32?_embed";

// Alle fast ansatte er medlemmer
export const FORSIDE_DEL_2 = "wp/v2/pages/94?_embed";

// Nyheter
export const POSTS_URL = "wp/v2/posts/";
export const NYHET_URL_SIDE = "wp/v2/pages/70?_embed";
export const NYHET_URL_KORT = "?_embed&categories=22";

// Utleie
export const UTLEIE_URL_SIDE = "wp/v2/pages/9?_embed";
export const UTLEIE_URL_BOOKING = "wp/v2/pages/125?_embed";
export const UTLEIE_URL_HAFJELL = "wp/v2/pages/129?_embed";
export const UTLEIE_URL_BEITOSTØLEN = "wp/v2/pages/131?_embed";

// Kontakt
export const KONTAKT_URL_BILDE = "wp/v2/pages/18?_embed";
export const KONTAKT_URL_SPØRSMÅL = "?_embed&categories=21";

// Om oss
export const OMOSS_URL_BILDE = "wp/v2/pages/8?_embed";
export const OMOSS_URL_INFO = "wp/v2/pages/191?_embed";
export const OMOSS_URL_LEADER = "wp/v2/posts/?_embed&categories=23";
export const OMOSS_URL_MEMBER = "wp/v2/posts/?_embed&categories=24";
export const OMOSS_URL_VARA = "wp/v2/posts/?_embed&categories=27";
export const OMOSS_URL_JOIN = "wp/v2/pages/193?_embed";

// Tilskudd
export const TILSKUDD_URL_BILDE = "wp/v2/pages/54?_embed";
export const TILSKUDD_URL_INFO = "wp/v2/pages/213?_embed";
export const TILSKUDD_URL_EXAMPLES = "wp/v2/posts/?_embed&categories=25";

// Jul
export const JUL_URL = "wp/v2/pages/389?_embed";
