import Heading from "../../layout/Heading";
import { useState, useEffect } from "react";
import { BASE_URL, TILSKUDD_URL_EXAMPLES } from "../../../constants/api";
import Alert from "react-bootstrap/Alert";
import TextLoader from "../../common/loader/TextLoader";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function GrantExampels() {
  const [examples, setExamples] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = BASE_URL + TILSKUDD_URL_EXAMPLES + "&per_page=30";

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            console.log(json);
            setExamples(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <TextLoader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }

  return (
    <>
      <Container className="grant-examples">
        <Heading size="2" content="Tilskudd kan for eksempel søkes til:" />
        <div className="grant-examples-container">
          {examples.map(function (item) {
            if (item._embedded["wp:featuredmedia"]) {
              return (
                <div data-aos="zoom-in" data-aos-duration="450" key={item.id}>
                  <p className="inline-wp-styles" dangerouslySetInnerHTML={{ __html: item.title.rendered }}></p>
                  <img src={item._embedded["wp:featuredmedia"]["0"].source_url} alt={item._embedded["wp:featuredmedia"]["0"].alt_text} />
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="grant-examples-submisson">
          <Heading size="3" content="Har du noen ønsker til andre tilskudd vi kan legge til?" />
          <p>Tilskudd innvilges ikke til arrangementer som julebord, lønningspils og liknende. </p>
          <Link className="cta-standard" to="/contact">
            Send inn forslag
          </Link>
        </div>
      </Container>
    </>
  );
}

export default GrantExampels;
