import Heading from "../../layout/Heading";

function Banner({ bannerImage, bannerClasses, topContent, bottomContent, addId }) {
  return (
    <>
      <div style={{ backgroundImage: `url(${bannerImage})` }} className={bannerClasses}>
        <div data-aos="fade-down" className="banner-headings" id={addId}>
          <Heading size="1" content={topContent} />
          <Heading size="1" content={bottomContent} />
        </div>
      </div>
    </>
  );
}

export default Banner;
