import Accordion from "react-bootstrap/Accordion";
import { useState, useEffect } from "react";
import { BASE_URL, POSTS_URL, KONTAKT_URL_SPØRSMÅL } from "../../../constants/api";
import Alert from "react-bootstrap/Alert";
import TextLoader from "../../common/loader/TextLoader";

function FaqAccordion() {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = BASE_URL + POSTS_URL + KONTAKT_URL_SPØRSMÅL + "&per_page=50";

  useEffect(
    function () {
      async function fetchData() {
        try {
          // Fetch the api from the url
          const response = await fetch(url);

          // if the response is successful, set the array as info state
          if (response.ok) {
            const json = await response.json();
            setInfo(json);
          } else {
            // If failed to reach api, set this as error
            setError("Kunne ikke laste inn informasjon");
          }
        } catch (error) {
          // Stringify the error value?
          setError(error.toString());
        } finally {
          // Will run regardless of fail or not
          setLoading(false);
        }
      }

      // Call the api
      fetchData();
    },
    // Dependency array, tells useEffect to only trigger when this array is changed, as it is when we call it.
    [url]
  );

  // Loader is displayed until content is loaded
  if (loading) {
    return <TextLoader />;
  }

  // If error state has a value, display this
  if (error) {
    return <Alert variant="danger">Feil: Kunne ikke laste inn informasjon, prøv igjen senere</Alert>;
  }
  return (
    <div>
      {info.map(function (item) {
        return (
          <>
            <Accordion.Item eventKey={item.id}>
              <Accordion.Header>{item.title.rendered}</Accordion.Header>
              <Accordion.Body className="inline-wp-styles" dangerouslySetInnerHTML={{ __html: item.content.rendered }}></Accordion.Body>
            </Accordion.Item>
          </>
        );
      })}
    </div>
  );
}

export default FaqAccordion;
