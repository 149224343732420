import HomeBanner from "../../common/banners/HomeBanner";
import HomeInfoOne from "../../common/homeComponents/HomeInfoOne";
import HomeInfoTwo from "../../common/homeComponents/HomeInfoTwo";
import HomeNews from "../../common/homeComponents/HomeNews";
import { Container } from "react-bootstrap";
import ToTop from "../../common/toTop/ToTop";

function Home() {
  return (
    <main id="main">
      <HomeBanner />
      <Container className="home-info setBackgroundHome">
        <HomeInfoOne />
        <HomeInfoTwo />
      </Container>
      <HomeNews />
      <ToTop />
    </main>
  );
}

export default Home;
